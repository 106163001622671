import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pietro/GitHub/chicco/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I am a Lecturer in Demography at the `}<a parentName="p" {...{
        "href": "https://www.sociology.ox.ac.uk/people/francesco-rampazzo"
      }}>{`Department of Sociology`}</a>{`, the `}<a parentName="p" {...{
        "href": "https://www.demographicscience.ox.ac.uk"
      }}>{`Leverhulme Centre for Demographic Science`}</a>{`,
and `}<a parentName="p" {...{
        "href": "http://www.nuffield.ox.ac.uk"
      }}>{`Nuffield College`}</a>{` at the University of Oxford. I hold a PhD in Social Statistics and Demography from the `}<a parentName="p" {...{
        "href": "https://www.southampton.ac.uk/demography/index.page"
      }}>{`University of Southampton`}</a>{`, during my PhD I was a Doctoral Fellow
at the `}<a parentName="p" {...{
        "href": "https://www.demogr.mpg.de/en"
      }}>{`Max Planck Institute for Demographic Research`}</a>{`. I collaborate with researchers at the Max Planck Institute for Demographic Research in Rostock and the Centre for Population Change in Southampton.
I continue to be affiliated with the `}<a parentName="p" {...{
        "href": "https://www.sbs.ox.ac.uk/research/centres-and-initiatives/oxford-future-marketing-initiative"
      }}>{`Future of Marketing Initiative`}</a>{` at Saïd Business School. `}</p>
    <p>{`I am a demographer with a broad range of research interests, which include digital and computational demography with applications in fertility, migration, and survey research.`}</p>
    <p><a parentName="p" {...{
        "href": "/assets/Francesco-Rampazzo-CV.pdf"
      }}>{`Curriculum Vitae`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      